import React, { useState, useEffect } from "react"
import Sidebar from "@components/vertical-sidebar/VerticalSidebar"
// List page
import PropertyDetailPage from "../pages/new-developments/list"
import Seo from "@components/seo"
import "../styles/index.scss"
import { isLoggedIn, handleLoggedInUserOffices } from "../utility/Auth";
import { navigate } from "@reach/router";

const IndexPage = () => {
  const [isAuth, setIsAuth] = useState(isLoggedIn());
  useEffect(async () => {
    if (isAuth) {
      //Fetch offices of logged in user
      await handleLoggedInUserOffices();
      navigate('/new-developments/list');
    } else {
      navigate('/auth/login');
    }
  }, [isAuth]);

  return (
    <>
      <Seo title="DNG" />
      <div className="main-wrapper">
        <Sidebar menuActive="new-developments" />
        <PropertyDetailPage />
      </div>
    </>
  )
}

export default IndexPage
